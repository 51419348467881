import girl from '../resourses/icons/girl.svg'
import boy from '../resourses/icons/boy.svg'

const getPhotoByGender = (src, gender) => {
  const validExtensions = ['.jpg', '.jpeg', '.png', '.webp'];
  for (let ext of validExtensions) {  
    if (src.toLowerCase().endsWith(ext)) {  
        return src;  
    }  
  }
  if (gender === "1") {
    return boy
  } 
  if (gender === "0") {
    return girl
  } 
}
export default getPhotoByGender;