import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import md5 from "react-native-md5";
import PanelHeader from "../../components/panelHeader/PanelHeader";
import AdminForm from "../../components/AdminForm/AdminForm";
import ModalAlert from "../../components/ModalAlert/ModalAlert";
import Spinner from "../../components/Spinner/Spinner";
import { useSelector } from "react-redux";
import { useHttp } from "../../hooks/http.hook";

const Editor = () => {
  const admin = useSelector((state) => state.admin.admin);

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState("");
  const [administrator, setAdministrator] = useState(admin);

  const navigate = useNavigate();
  const form = useRef(0);
  const { request, process, setProcess, } = useHttp();

  const onChangeAdmin = () => {
    setLoading(true);
    request(undefined, {
      command: "admin.update",
      id: administrator.id,
      name: administrator.name,
      login: administrator.login,
      password: md5.hex_md5(administrator.password),
      role: administrator.role,
      email: administrator.email,
      comment: administrator.comment,
    }).then((res) => {
      setProcess("confirm");
      // получаем код ответа и в зависимости от него формируем текст сообщения
      if (res.status === "1" && res.message[0].message) {
        // setTextAlert('Admin has been successfully created');
        navigate("/admins-list");
      } else {
        setShowAlert(true);
        setTextAlert("Something went wrong");
      }
      setLoading(false);
    });
  };
  let spinner;
  loading ? (spinner = <Spinner active />) : (spinner = <Spinner />);
  return (
    <>
      <PanelHeader title="Edit an admin" showBackBtn={true} />
      {spinner}
      <AdminForm admin={administrator} setAdmin={setAdministrator} buttonTitle="Edit an admin" sendData={onChangeAdmin} form={form}></AdminForm>
      <ModalAlert showAlert={showAlert} setShowAlert={setShowAlert} message={textAlert} />
    </>
  );
};

export default Editor;
