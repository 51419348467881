import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ModalAlert from '../../components/ModalAlert/ModalAlert';
import PanelHeader from '../../components/panelHeader/PanelHeader';
import BtnPrint from "../../components/BtnPrint/BtnPrint";
import SetContent from '../../utils/SetContent';
import { useHttp } from '../../hooks/http.hook';

import './_order.scss';
import attention from './../../resourses/icons/attention.svg';
const Order = () =>  {
  const {id} = useParams();

  const allergens = useSelector((state => state.matrix.allergens));
  const typeDishes = useSelector((state => state.matrix.typeDishes));
  const workdays = useSelector((state) => state.workdays.nextFiveDays);
  const selectDay = useSelector((state) => state.workdays.selectDay);
 
  const {request, process, setProcess} = useHttp();

  const [activeTab, setActiveTab] = useState(selectDay.weekday)
  const [showAlert, setShowAlert] = useState(false);
  const [order, setOrder] = useState({
    id: '',
    avatar: '',
    name: '',
    nickname: '',
    address: '',
    phone: '',
    deliveryTime: '0',
    allergens: [],
    weekdays: []
  });

  const filterDishByDay = order.weekdays.filter((dish) => dish.day === activeTab)


  //отправляем запрос получния данных о заказе
  useEffect(() => { 
      request(undefined, {
        "command": 'kitchen.order_read',
        "id" : `${id}`
      }).then((res)=>{
        setOrder({
          ...res.message[0],
          weekdays: res.message[0].weekdays.map(itemObj =>{
            return {
              ...itemObj,
              photo: JSON.parse(itemObj.photo)
            }
          })
        })
        setProcess('confirmed');
      });
  }, []);

  const getPhotoSrc = (arrPhoto) => {
    return arrPhoto.find((item, i) => {
        if(item.includes('.jpg')  || item.includes('.png') || item.includes('.webp')) {
          return item
        }
    })
  }

  const changeDay = (dayId) => {
    setActiveTab(dayId);
  }

  const toggleDish = (id, day) => {
    setOrder(state => {
      return {
        ...state,
        weekdays: state.weekdays.map(itemObj => {
          if (itemObj.id === id && itemObj.day === day) {
            return {
              ...itemObj,
              added: itemObj.added === '1' ? '0' : '1'
            }
          } else return itemObj
        })
      }
    })
  }

  // отправка отмеченых блюд в заказе
  const onSave = () => {
    const dishes = {}
    filterDishByDay.forEach((dish) => {
      dishes[dish.id] = dish.added
    })
  
    console.log(id);
    request(undefined, {
      "command": 'kitchen.order_update',
      "id" : `${id}`, // id заказа,
      "dishes": JSON.stringify(dishes)
    }).then((res) => {
      setProcess('confirmed');
      setShowAlert(true)
    });
  }

  const renderOrder = () => {
    return (
      <div className="child-form">
        <div className="child-form__inner">
          <div className="child-form__left">
            <div className="child-form__avatar">
              <img src={order.photo} alt="" />
            </div>
          </div>
          <div className="child-form__right">
            <div className="child-form__col-box">
              <div className="child-form__col">
                <label>
                  <span className="child-form__label-name">id</span>
                  <input type="text" name="id" value={order.id} readOnly/>
                </label>
                <label>
                  <span className="child-form__label-name">Name Surname Middle name</span>
                  <input type="text" name="name" value={order.name} readOnly/>
                </label>
                <label>
                  <span className="child-form__label-name">Phone</span>
                  <input type="text" name="name" value={order.phone} readOnly/>
                </label>
              </div>
              <div className="child-form__col">
                <label>
                  <span className="child-form__label-name">Nickname</span>
                  <input type="text" name="nickname" value={order.nickname} readOnly/>
                </label>
                <label>
                  <span className="child-form__label-name">Address</span>
                  <input type="text" name="address" value={order.address} readOnly/>
                </label>
                <label>
                  <span className="child-form__label-name">Delivery time</span>
                  <input type="text" name="address" value={order.deliveryTime === '0' ? '7 AM to 11 AM (Morning)' : '6 PM to 10 PM (the night before)'} readOnly/>
                </label>
              </div>
            </div>

            <div className="child-form__checkboxes">
              <span className="child-form__label-name">What do you not eat?</span>
                <div className="child-form__checkbox-list">
                  {
                    allergens.map((item, i) => {
                      // перебираем все аллергены и если в заказе приходит отмеченный аллерген - отрисовываем его
                      if (order.allergens[i] === '1') {
                        return (
                          <label className="checkbox-sm" key={i}>
                            <input className="sr-only"
                            type="checkbox"
                            name="allergens"
                            value={item}
                            checked={true}
                            />
                            <div></div>
                            <span>{item}</span>
                        </label>
                        )
                      }
                    })
                  }
              </div>
            </div>

            <div className="order__tabs tabs">
              {workdays.map(day => {
                return  <button key={day.weekday}
                  className={`tabs__btn ${activeTab === day.weekday ? 'active' : ''}`}
                  onClick={() => changeDay(day.weekday)}>
                    {day.date}
                </button>
              })}
            </div>

            <ul className="order__dish-list">
              {order.weekdays.filter((dish) => dish.day === activeTab).map(dish => {
                const {id, photo, day, name, type, kcal, pro, carb, fat, containAllergen, added} = dish;
                return (
                  <li key={id}
                    className={`order__dish-item ${activeTab === selectDay.weekday ? 'edit' : ''}`}
                    onClick={activeTab === selectDay.weekday ? () => toggleDish(id, day) : undefined}>
                    <div className="order__img-box">
                      <img className="order__img" src={getPhotoSrc(photo)} alt="dish"/>
                      { containAllergen === '1' ? 
                        <>
                          <img className="order__attention-icon" src={attention} />
                          <div className="order__attention">Contains an allergen</div>
                        </> : null }
                   
                    </div>
                    <div className="order__info">
                      <div className="order__check">
                        <div className={`status ${added === '1' ? 'ok': 'disable'}`}></div>
                        {added ? 'Added' : 'Not added'}
                      </div>
                      <div className="order__type">{typeDishes[+type]}</div>
                      <div className="order__name">{name}</div>
                      <div className="order__params">
                        <span>{kcal} kcal</span>
                        <span>{pro}g Pro</span>
                        <span>{carb}g Carb</span>
                        <span>{fat}g Fat</span>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
            {
              selectDay.weekday === activeTab ?
              <button className="order__save button" onClick={() => onSave()}>save</button> :
              null
            }
            
          </div>
        </div>

      </div>
    )
  }

  return (
    <>
      <PanelHeader title="Children" showBackBtn={true}>
        <BtnPrint/>
      </PanelHeader>

      <SetContent process={process} component={renderOrder()}/>
      <ModalAlert showAlert={showAlert} setShowAlert={setShowAlert} message={"The changes are saved"}/>
    </>
  )
}

export default Order;