import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import {useSelector, useDispatch} from "react-redux";
import { addAdmins } from "../../redux/slices/adminSlice";
import PanelHeader from './../../components/panelHeader/PanelHeader';
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import ModalAlert from "../../components/ModalAlert/ModalAlert";
import { editAdmin } from '../../redux/slices/adminSlice';
import { useHttp } from "../../hooks/http.hook";
import SetContent from "../../utils/SetContent";
import trash from "./../../resourses/icons/trash.png";
import './_admins.scss'
const AdminList = () => {

    const [showAlert, setShowAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const [targetConfirm, setTargetConfirm] = useState({
        id: null,
        name: null,
        login: null
    });

    const adminsList = useSelector((state => state.admin.adminList));
    const dispatch = useDispatch();
    const {request, process, setProcess} = useHttp(); 
   

    ///получаем список админов orderBy('id')
    const getAdminList = () => {
        request(undefined, {
                "command": "admin.list_read"
            })
            .then(res => {
                setProcess('confirmed');
                dispatch(addAdmins(res.message))
            })
    }

    useEffect(() => {
        getAdminList();
        // eslint-disable-next-line
    }, [])

    const deleteAdmin = (id) => {
        setShowConfirm(false)
        request(undefined, {
            "command": "admin.remove_admin",
            "id": id        
        })
        .then(res => {
            if (res.status === '1') {
                setShowAlert(true)
                setTextAlert('The admin has been successfully deleted')
                getAdminList()
                setProcess('confirmed')
            } else {
                setProcess('error')
            }
        })
    }
    const onConfirmDelete = (id, name, login) => {
        setShowConfirm(true);
        setTargetConfirm({id, name, login})
    } 

    const admins = adminsList.map((item, i) => (
        <li key={item.id} className="admin__item">
            <div className='admin__info'>
                <span>{item.name}</span>
                <span>{item.login}</span>
                <span>{item.email}</span>
               <Link className="button" onClick={() => dispatch(editAdmin(item))} to="/edit-admin">Edit</Link>
            </div>
            <button 
                className='admin__trash btn-trash'
                onClick={() => onConfirmDelete(item.id, item.name, item.login)}
                >
                <img src={trash} alt="delete"/>
            </button>
        </li>
	))

    return (
        <>
            <PanelHeader title="List of admins"/>
            <ul className="admin">
                <SetContent process={process} component={admins}/>
            </ul>
            <Link to={`/create-admin`} className="dish__add-dish button">Create an admin</Link>
            <ConfirmModal question='Delete the admin?' text1={targetConfirm.name} text2={targetConfirm.login} showConfirm={showConfirm} setShowConfirm={setShowConfirm} actionConfirmed={() => deleteAdmin(targetConfirm.id)}/>
            <ModalAlert showAlert={showAlert} setShowAlert={setShowAlert} message={textAlert} />
        </>
    )
}

export default AdminList;