import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PanelHeader from "../../components/panelHeader/PanelHeader";
import Pagination from "../../components/Pagination/Pagination";

import { useHttp } from "../../hooks/http.hook";
import SetContent from "../../utils/SetContent";

import "./_dishlist.scss";

const DishList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [dishList, setDishList] = useState([]);
  const [totalPages, setTotalPages] = useState(null);

  const typeDishes = useSelector((state) => state.matrix.typeDishes);
  const { request, process, setProcess } = useHttp();

  // get dish list
  useEffect(() => {
    getDishList(currentPage);
    // eslint-disable-next-line
  }, []);

  const changePage = (step) => {
    // step  -1 means previous page and +1 is next
    getDishList(currentPage + step);
    setCurrentPage((state) => state + step);
  };

  const getDishList = (pageNumber) => {
    request(undefined, {
      command: "dish.read_dish",
      page: `${pageNumber}`,
    }).then((res) => {
      console.log(res);
      setCurrentPage(res.message[0].current_page);
      setTotalPages(res.message[0].max_page);
      setDishList(res.message[0].dishes[0]);
      setProcess("confirmed");
    });
  };

  //render table rows
  const renderItems = (arr) => {
    const dishList = arr.map((item, i) => {
      const { id, type, name } = item;
      return (
        <li key={id} className="list__item">
          <div className="list__id">{id}</div>
          <div className="list__col-2">{typeDishes[type]}</div>
          <div className="list__col-3">{name}</div>
          <Link to={`/edit-dish/${id}`} className="dish__edit">
            Edit
          </Link>
        </li>
      );
    });
    return <ul className="list">{dishList}</ul>;
  };

  return (
    <>
      <PanelHeader title="Meals" />

      <SetContent process={process} component={renderItems(dishList)} />

      <div className="dish__bottom">
        <Link to={`/new-dish`} className="dish__add-dish button">
          Add a dish +
        </Link>
        <Pagination currentPage={currentPage} totalPages={totalPages} changePage={changePage} />
      </div>
    </>
  );
};

export default DishList;
