import React, { useState, useEffect } from 'react';
import { useParams} from 'react-router-dom';
import DishForm from '../../components/DishForm/DishForm';
import PanelHeader from '../../components/panelHeader/PanelHeader';
import SetContent from "../../utils/SetContent";
import { useHttp } from '../../hooks/http.hook';

function EditDish() {
  const {id} = useParams(); // тут будем получать id блюда, и по нему будем отправлять запрос

  const {request, process, setProcess} = useHttp();


  const [dish, setDish] = useState({
    name: '',
    arabian: '',
    type: '',
    kcal: '',
    carbs: '',
    fats: '',
    proteins: '',
    img1: '',
    img2: '',
    img3: '',
    allergens: [],
    days: []
  }); // параметры блюда, 



  //отправляем запрос получния данных о блюде
  useEffect(() => { 
    request(undefined, {
      "command": "dish.get_dish",
      "id" : id
    }).then((res)=>{
      setDish({...res.message[0],
        ingredients: res.message[0].ingredients.join(),
        ingredientsAr: res.message[0].ingredientsAr.join(),
        ingredientsDelete: res.message[0].ingredientsDelete.join(),
        ingredientsDeleteAr: res.message[0].ingredientsDeleteAr.join(),
      })
      setProcess('confirmed');
    });
   // eslint-disable-next-line
  }, []);

  return (
    <>
      <PanelHeader title="Meals"/>
      <SetContent
        process={process}
        component={<DishForm dish={dish} setDish={setDish}/>}
      />
      
    </>
  )
}

export default EditDish;