import React, { useState, useEffect, useRef } from "react";
import "./_slider.scss";
import PanelHeader from "./../../components/panelHeader/PanelHeader";
import happyTaco from "./../../resourses/icons/happy-taco.png";
import Spinner from "./../../components/Spinner/Spinner";
import { useHttp } from "../../hooks/http.hook";
import SetContent from "../../utils/SetContent";

const MainSlider = () => {
  const [cardsArr, setCardsArr] = useState([]);

  const { request, process, setProcess } = useHttp();

  const getCardsArr = () => {
    request(undefined, {
      command: "slider.get_slider",
    }).then((res) => {
      setCardsArr(res.message);
      setProcess("confirmed");
    });
  };

  useEffect(() => {
    getCardsArr();
    // eslint-disable-next-line
  }, []);

  const renderItems = (cardArray) => {
    const cards = cardArray.map((card) => (
      <Card key={card.id} {...card} getCardsArr={getCardsArr} />
    ));
    return <ul className="slider">{cards}</ul>;
  };

  return (
    <>
      <PanelHeader title="Main Slider" />
      <SetContent process={process} component={renderItems(cardsArr)} />
    </>
  );
};

const Card = ({ slideName, id, getCardsArr, title, descr, titleArabian, descrArabian }) => {
  const [loading, setLoading] = useState(false);
  const [sended, setSended] = useState(false);
  const inputTitle = useRef(0);
  const inputDescr = useRef(0);

  let spinner;
  loading ? (spinner = <Spinner active />) : (spinner = <Spinner />);
  const button = useRef(0);
  const [card, setCard] = useState({
    title: `${title}`,
    descr: `${descr}`,
    titleArabian: `${titleArabian}`,
    descrArabian: `${descrArabian}`,
  });
  const [valid, setValid] = useState(true);
  const { request } = useHttp();
  let clazz;
  clazz = valid ? "input" : "input input--error";

  useEffect(() => {
    button.current.setAttribute("disabled", true);
     // eslint-disable-next-line
  }, []);

  const onCheckDisable = (e) => {
    if (e.target.value.length > 10) {
      setValid(true);
      button.current.removeAttribute("disabled");
    } else {
      setValid(false);
      button.current.setAttribute("disabled", true);
    }
  };
  const onSendNewSlideContent = (card) => {
    setLoading(true);
    request(undefined, {
      command: "slider.set_slider",
      id: id,
      title: card.title,
      name: card.slideName,
      descr: card.descr,
      titleArabian: card.titleArabian,
      descrArabian: card.descrArabian,
    }).then((res) => {
      setLoading(false);
      setSended(true);
    });
  };
  return (
    <>
      {spinner}
      <li className="card__item card__item--twice">
        <h3 className="card__title">{slideName}</h3>
        <form>
          {sended ? (
            <>
              <img className="card__taco card__taco--twice" src={happyTaco}  alt="happy taco"/>
              <button
                className="button"
                type="button"
                onClick={() => {
                  setSended(false);
                  getCardsArr();
                }}
              >
                Ok
              </button>
            </>
          ) : (
            <>
              <label className="label">
                Title
                <input
                  ref={inputTitle}
                  type="text"
                  name="title"
                  className={clazz}
                  placeholder={card.title}
                  value={card.title}
                  onChange={(e) => {
                    setCard({ ...card, [e.target.name]: e.target.value });
                    onCheckDisable(e);
                  }}
                ></input>
              </label>
              <label className="label">
                Arabian Title
                <input
                  ref={inputTitle}
                  type="text"
                  name="titleArabian"
                  className={clazz}
                  placeholder={card.titleArabian}
                  value={card.titleArabian}
                  onChange={(e) => {
                    setCard({ ...card, [e.target.name]: e.target.value });
                    onCheckDisable(e);
                  }}
                ></input>
              </label>
              <label className="label">
                Description
                <textarea
                  ref={inputDescr}
                  type="text"
                  name="descr"
                  className="input"
                  placeholder={card.descr}
                  value={card.descr}
                  onChange={(e) => {
                    setCard({ ...card, [e.target.name]: e.target.value });
                    setValid(true);
                    button.current.removeAttribute("disabled");
                  }}
                ></textarea>
              </label>
              <label className="label">
                Arabian Description
                <textarea
                  ref={inputDescr}
                  type="text"
                  name="descrArabian"
                  className="input"
                  placeholder={card.descrArabian}
                  value={card.descrArabian}
                  onChange={(e) => {
                    setCard({ ...card, [e.target.name]: e.target.value });
                    setValid(true);
                    button.current.removeAttribute("disabled");
                  }}
                ></textarea>
              </label>
              <button ref={button} className="button" type="button" onClick={() => onSendNewSlideContent(card)}>
                Save
              </button>
            </>
          )}
        </form>
      </li>
    </>
  );
};
export default MainSlider;
