import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PanelHeader from "../../components/panelHeader/PanelHeader";
import Pagination from "../../components/Pagination/Pagination";
import ModalAlert from "../../components/ModalAlert/ModalAlert";
import BtnPrint from "../../components/BtnPrint/BtnPrint";

import { useHttp } from "../../hooks/http.hook";
import SetContent from "../../utils/SetContent";

import "./_kitchen.scss";
const Kitchen = () => {

  const [showAlert, setShowAlert] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [kitchenList, setKitchenList] = useState([]);

  const { request, process, setProcess } = useHttp();

  const allergensByStr = useSelector((state) => state.matrix.allergens);
  const workdays = useSelector((state) => state.workdays.nextFiveDays);

  useEffect(() => {
    getKitchenList(workdays[activeTab].weekday, currentPage);
    // eslint-disable-next-line
  }, []);

  const getKitchenList = (date, pageNumber) => {
      request(undefined, {
        "command": "kitchen.order_day_list",
        "page": `${pageNumber}`,
        "date": `${date}`,
      }).then((res) => {
        setKitchenList(Object.values(res.message[0].order_list));
        setTotalPages(res.message[0].max_page);
        setCurrentPage(+res.message[0].current_page);
        setProcess("confirmed");
      });
  };

  const changePage = (step) => {
    getKitchenList(workdays[activeTab].weekday, currentPage + step);
    setCurrentPage(state => +state + step);
  };

  const changeDate = (i) => {
    setActiveTab(i);
    getKitchenList(workdays[i].weekday, 1);
  };
  // запрос на сохранение отмеченных блюд
  const onSave = (id, value) => {
      request(undefined, {
        "command": "kitchen.order_update_status",
        "id": `${id}`,
        // "status": value,
      }).then((res) => {
        if (res.status === '1') {
          setProcess('confirmed');
          setKitchenList((state) => {
            const newState = structuredClone(state);
            return newState.map((item) => {
              if (item.id === id) {
                return { ...item, status: item.status === '0' ? '1' : '0' };
              }
              return item;
            });
          });
        }
      }).catch(err => {
        setProcess('error')
      });
  };

  //render table rows
  const renderItems = (arr) => {
    const tableRows = arr.map((item) => {
      const { id, status, name, age, count, allergens, removeING, ING } = item;
      const arrRemoveING = removeING.split(',');
      const arrING = ING.split(',');
      const transformIngredients = [];
      
      arrRemoveING.forEach((item, i) => {
        if (item === "0" || item === "2") {
          transformIngredients.push(arrING[i]);
        }
      });
      for (let i = transformIngredients.length; i < 4; i++) {
        transformIngredients.push("");
      }
      const renderTd = transformIngredients.map((item,i) => <td key={i}>{item}</td>);

      return (
        <tr key={id} onClick={() => onSave(id, status)}>
          <td>
            <div className={`status ${status === "1" ? "ok" : "disable"}`}></div>
          </td>
          <td>{id}</td>
          <td>{name}</td>
          {renderTd}
          <td>{count}</td>
        </tr>
      );
    });
    return (
      <table className="table kitchen__table">
        <thead>
          <tr>
            <th></th>
            <th>id</th>
            <th>Item name</th>
            <th>Deleted ingredients #1</th>
            <th>Deleted ingredients #2</th>
            <th>Deleted ingredients #3</th>
            <th>Deleted ingredients #4</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    );
  };

  const tabs = workdays.map((item, i) => {
    return (
      <button key={i} className={`tabs__btn ${activeTab === i ? "active" : ""}`} onClick={() => changeDate(i)}>
        {item.date}
      </button>
    );
  });

  return (
    <>
      <PanelHeader title="Kitchen">
        <BtnPrint />
      </PanelHeader>

      <div className="kitchen__tabs tabs">{tabs}</div>

      <SetContent process={process} component={renderItems(kitchenList)} />

      <div className="kitchen__bottom">
        <Pagination currentPage={currentPage} totalPages={totalPages} changePage={changePage} />
      </div>
      <ModalAlert showAlert={showAlert} setShowAlert={setShowAlert} message={"The changes are saved"} />
    </>
  );
};

export default Kitchen;
